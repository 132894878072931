<template>
  <el-table
    :key="forceKey"
    class="sample-table"
    border
    :data="samples"
    height="100%"
    style="width: 100%"
    :row-class-name="tableRowClassName"
  >
    <el-table-column
      label=""
      align="center"
      width="35px"
      fixed="left"
    >
      <template
        slot-scope="scope"
      >
        <el-checkbox
          :value="getCheckboxValue(scope.row)"
          @change="(value) => handleSelectionChange(scope.row, value)"
        />
      </template>
      <template
        slot="header"
      >
        <el-checkbox
          :checked="allSamplesChecked"
          @change="(value) => checkAll(value)"
        />
      </template>
    </el-table-column>
    <el-table-column
      v-for="(column, index) in columns"
      :key="index"
      :prop="index"
      :label="$t(`samples.create.${column.label}`)"
      :align="column.align"
      :min-width="column.minWidth ? column.minWidth : ''"
      :width="column.width ? column.width : ''"
      :fixed="column.fixed"
    >
      <template
        slot-scope="scope"
      >
        <span v-if="column.type==='text'">{{ propertyValue(scope.row, column.key) }}</span>
        <span v-if="column.type === 'boolean'">
          <el-tag
            v-if="scope.row[index]"
            type="success"
          >√</el-tag>
          <el-tag
            v-else
            type="danger"
          >x</el-tag>
        </span>
        <span v-if="column.type==='date'">
          {{ propertyValue(scope.row, column.key) | date('DD MMMM YYYY') }}
        </span>
        <span v-if="column.type==='enum'">
          {{ $t(`enums.${column.enum}.${propertyValue(scope.row, column.key)}`) }}
        </span>
        <TableActions
          v-else-if="column.type === 'actions' && !isOrderReadOnly"
          :configuration="column"
          :row="scope.row"
          @actionClick="$emit($event, scope.row)"
        />
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import lodashGet from 'lodash.get';
import samplesColumns from '@/config/columns/samples';
import TableActions from '@/components/TableActions.vue';

export default {
  name: 'SampleItems',
  components: {
    TableActions,
  },
  props: {
    samples: { type: Array, default: () => [] },
    multipleSelection: { type: Array, default: () => [] },
    allSamplesChecked: { type: Boolean, default: false },
    isOrderReadOnly: { type: Boolean, default: false },
  },
  data() {
    return {
      columns: samplesColumns,
      forceKey: 1,
    };
  },
  watch: {
    allSamplesChecked() {
      // I know ... but element-ui doens't redraw the header ...
      this.forceKey += 1;
    },
  },
  methods: {
    getCheckboxValue(row) {
      return this.multipleSelection.some(val => val.id === row.id);
    },
    handleSelectionChange(row, value) {
      this.$emit('selectionChanged', { row, value });
    },
    propertyValue(object, path) {
      return lodashGet(object, path, '');
    },
    tableRowClassName({ row }) {
      if (this.multipleSelection.some(val => val.id === row.id)) {
        return 'row-selected';
      }
      return '';
    },
    checkAll(status) {
      this.samples.forEach((s) => {
        this.$emit('selectionChanged', { row: s, value: status });
      });
    },
  },
};
</script>
<style lang="scss">
  .sample-table {
    &.el-table {
      th {
        padding: 6px 0;
      }
      td {
        padding: 5px 0;
      }
    }
    .row-selected {
      background-color: $--table-row-hover-background-color;
    }
  }
</style>
