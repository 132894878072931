<template>
  <el-dialog
    visible
    @close="abort"
  >
    <p class="warning-description">
      {{ analysis.code }}
    </p>

    <el-table
      :key="redrawTable"
      border
      :data="analysis.children"
      style="width: 100%; margin-top: 2rem; margin-bottom: 2rem;"
    >
      <el-table-column
        label=""
        align="center"
        width="35px"
        fixed="left"
      >
        <template
          slot-scope="scope"
        >
          <el-checkbox
            :value="getCheckboxValue(scope.row)"
            @change="(value) => handleSelectionChange(scope.row, value)"
          />
        </template>
      </el-table-column>
      <el-table-column
        property="analysis.description"
        :label="analysis.description"
      />
    </el-table>

    <el-alert
      v-show="analysesNumberError"
      :closable="false"
      :title="$tc('dynamicAnalysesModal.wrongNumber', analysis.classType)"
      type="error"
      show-icon
    />

    <div class="buttons">
      <el-button
        type="secondary"
        @click.prevent="abort"
      >
        {{ $t('dynamicAnalysesModal.abort') }}
      </el-button>
      <el-button
        type="primary"
        @click.prevent="submit"
      >
        {{ $t('dynamicAnalysesModal.confirm') }}
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
// import FormButtons from '@/components/FormButtons.vue';

export default {
  name: 'DynamicAnalysesModal',
  components: {
    // FormButtons,
  },
  props: {
    analysis: { type: Object, default: () => {} },
  },
  data() {
    return {
      analysesNumberError: false,
      redrawTable: 0,
    };
  },
  mounted() {
  },
  methods: {
    getCheckboxValue(row) {
      return row.isSelected;
    },
    handleSelectionChange(row, value) {
      const child = this.analysis.children.find(val => val.id === row.id);
      child.isSelected = value;
      this.redrawTable += 1;
    },
    submit() {
      this.analysesNumberError = false;
      const analysesNumber = this.analysis.classType;
      const selectedChildrenAcc = (total, child) => total + (child.isSelected ? 1 : 0);
      const selectedChildrenNum = this.analysis.children.reduce(selectedChildrenAcc, 0);
      if (analysesNumber === selectedChildrenNum) {
        this.$emit('dialogClose', {
          success: true,
          analysis: this.analysis,
        });
      } else {
        this.analysesNumberError = true;
      }
    },
    abort() {
      this.$emit('dialogClose', {
        success: false,
        analysis: this.analysis,
      });
    },
  },
};
</script>


<style lang="scss">
.buttons {
  margin-top: 1rem;
}

.warning-title {
  font-size: $--font-size-large;
  font-weight: $--font-weight-primary;
  text-transform: uppercase;
  text-align: center;
  padding-bottom: 1rem;
}

.warning-description {
  text-align: center;
}

.lot-update-form {
  padding-top: 2rem;
}

</style>
