<template>
  <el-dialog
    visible
    @close="abort"
  >
    <ContentHeader
      slot="title"
      :title="$t('accreditedTestModal.title')"
    />
    <ValidationObserver v-slot="{ handleSubmit, invalid }">
      <h2 class="warning-title">
        {{ $t('accreditedTestModal.warning') }}
      </h2>
      <p class="warning-description">
        {{ $t('accreditedTestModal.description') }}
        {{ $tc('accreditedTestModal.samples', samplesToComplete.length) }}
      </p>
      <el-form
        ref="form"
        class="lot-update-form"
        label-position="top"
      >
        <el-row
          :gutter="20"
          type="flex"
        >
          <el-col :span="12">
            <FormItem
              :label="$t('accreditedTestModal.lot', [formData.productName])"
              rules="required"
            >
              <el-input
                v-model="formData.lot"
              />
            </FormItem>
          </el-col>
        </el-row>

        <FormButtons>
          <el-button
            type="secondary"
            @click.prevent="abort"
          >
            {{ $t('accreditedTestModal.abort') }}
          </el-button>
          <el-button
            type="primary"
            native-type="submit"
            :disabled="invalid"
            @click.prevent="handleSubmit(submit)"
          >
            {{ samplesToComplete.length === 1 ?
              $t('accreditedTestModal.confirm') : $t('accreditedTestModal.next') }}
          </el-button>
        </FormButtons>
      </el-form>
    </ValidationObserver>
  </el-dialog>
</template>

<script>
import { ordersApi } from '@/api';
import FormItem from '@/components/FormItem.vue';
import ContentHeader from '@/components/ContentHeader.vue';
import FormButtons from '@/components/FormButtons.vue';

const defaultData = {
  id: null,
  lot: '',
  productName: '',
};

export default {
  name: 'AccreditedTestModal',
  components: {
    FormItem,
    ContentHeader,
    FormButtons,
  },
  props: {
    uncompletedSamples: { type: Array, default: () => [] },
  },
  data() {
    return {
      samplesToComplete: [],
      formData: { ...defaultData },
      updatedSamples: {},
    };
  },
  mounted() {
    this.samplesToComplete = this.uncompletedSamples;
    if (this.samplesToComplete.length) {
      const [firstSample] = this.samplesToComplete;
      this.formData = { ...firstSample };
    }
  },
  methods: {
    async submit() {
      try {
        await ordersApi.patchSample(
          this.$route.params.orderId,
          this.formData.id,
          { lot: this.formData.lot },
        );
        this.updatedSamples[this.formData.id] = this.formData;
        if (this.samplesToComplete.length === 1) {
          this.$emit('dialogClose', { success: true, updatedSamples: this.updatedSamples });
          this.formData = { ...defaultData };
          return;
        }
        const [, firstSample, ...restOfSamples] = this.samplesToComplete;
        this.samplesToComplete = [firstSample, ...restOfSamples];
        this.formData = { ...firstSample };
      } catch (error) {
        this.$notify({ showClose: true, type: 'error', message: error.response.data.error.message });
      }
    },
    abort() {
      this.$emit('dialogClose', {
        success: false,
        dirty: this.samplesToComplete.length < this.uncompletedSamples.length,
        updatedSamples: this.updatedSamples,
      });
      this.formData = { ...defaultData };
    },
  },
};
</script>


<style lang="scss">

.warning-title {
  font-size: $--font-size-large;
  font-weight: $--font-weight-primary;
  text-transform: uppercase;
  text-align: center;
  padding-bottom: 1rem;
}

.warning-description {
  text-align: center;
}

.lot-update-form {
  padding-top: 2rem;
}

</style>
