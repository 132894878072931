<template>
  <el-dialog
    visible
    @close="$emit('dialog-close')"
  >
    <ContentHeader
      slot="title"
      :title="title"
    />
    <slot name="content" />
  </el-dialog>
</template>

<script>
import ContentHeader from '@/components/ContentHeader.vue';

export default {
  name: 'Modal',
  components: {
    ContentHeader,
  },
  props: {
    title: { type: String, default: '' },
  },
};
</script>
