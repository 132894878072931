const samplesColumns = {
  name: {
    key: 'productName',
    label: 'productName',
    type: 'text',
    sortable: false,
    minWidth: '250px',
  },
  lot: {
    key: 'lot',
    label: 'lot',
    type: 'text',
    sortable: false,
    minWidth: '150px',
  },
  actions: {
    label: 'actions',
    type: 'actions',
    sortable: false,
    fixed: 'right',
    width: '165px',
    align: 'center',
    buttons: [
      {
        name: 'cloneSample',
        icon: 'fas fa-clone',
        color: 'blue',
      },
      {
        name: 'editSample',
        icon: 'fas fa-pencil',
        color: 'blue-light',
      },
      {
        name: 'deleteSample',
        icon: 'fas fa-trash-alt',
        color: 'red',
      },
      {
        name: 'selectOnlyThisSampleOn',
        icon: 'fas fa-eye',
        color: 'gray',
        showCondition: row => !row.solo,
      },
      {
        name: 'selectOnlyThisSampleOff',
        icon: 'fas fa-eye',
        color: 'green',
        showCondition: row => !!row.solo,
      },
    ],
  },
};

export default samplesColumns;
